<template>

    <v-chip
        class="person-label immutable-text"
        small
        label
        style="cursor: default!important"
    >
        <div
            class="if-more-tooltip-chip"
            v-tooltip.left-center="Description"
        >    
            <i :class="[type == 'email' ? 'fas fa-envelope' : 'fas fa-phone-alt', 'dark-gray-color']"></i>
            <div>{{ Title }}</div>
        </div>
        <div 
            class="pl-actions-wrapper"
        >
            <span
                v-if="!isReadOnly"
                v-tooltip.left-center="$t('Редактировать')"
                @click="editContact()"
            >
                <i class="fas fa-edit dark-gray-color"></i>
            </span>
            <span
                v-if="!isReadOnly"
                v-tooltip.left-center="$t('Удалить')"
                @click="deleteContact()"
            >
                <i class="fas fa-trash dark-gray-color"></i>
            </span>
        </div>
    </v-chip>

</template>

<script>

export default {
    name: "ContactItem",
    data() {
        return {
            
        }
    },
    props: {
        type: {
            type: String,
            default: "email"
        },
        colWidth: {
            type: String,
            default: "3"
        },
        icon: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        IsActive: {
            type: Boolean,
            default: false
        },
        Progress: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        isReadOnly: {
            type: Boolean,
            default: false
        },
        hideLoad: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        Title() {
            if (this.title.length > 40)
                return `${this.title.substring(0, 39)}...`;
            else
                return this.title;
        },
        Description() {
            let result = `${this.$t('Контакт')}: ${this.title}</br>`;

            if (this.description)
                result += `${this.$t('Описание')}: ${this.description}`;

            return result;
        }
    },
    methods: {
        onContactClick()
        {
            //this.$emit('on-click-attachment');
        },
        async editContact() {
            this.$emit('editcontact', this.$vnode.key);
        },
        deleteContact()
        {
            this.$emit('deletecontact', this.$vnode.key);
        }
    }
}
</script>